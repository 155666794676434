<template lang="pug">
.wait-btn-wrapper
  v-btn(
    data-test="c-wait-list-btn"
    :class="{'wait-list-btn_not': !isWaitList, 'wait-list-btn_in' : isWaitList}"
    @click="openDialog"
    outlined small
    :color="color"
  ).wait-list-btn {{ btnText }}
  accept-dialog(
    :title="btnText"
    :descr="modalDescr"
    v-model="showing"
    @okBtnClicked="send"
  )

</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'

export default {
  mixins: [errorsMixin],

  props: {
    isWaitList: {
      required: true,
      type: Boolean
    },
    ID: {
      required: true
    }
  },

  data: () => ({
    showing: false
  }),

  inject: ['svc'],

  computed: {
    color() {
      if(this.isWaitList) return 'error'
      return 'warning'
    },
    btnText() {
      if(!this.isWaitList) return 'Add to wait list'
      return 'Remove from wait list'
    },

    modalDescr() {
      if(!this.isWaitList) return 'Are you sure you want to add the candidate to the wait list?'
      return 'are you sure you want to remove the candidate to the waiting list'
    },
  },

  methods: {
    openDialog(){ 
      this.showing = true
    },

    Svc() {
      return this.svc()
    },

    async send() {
      try {
        await this.Svc().saveIsWaitListStatus(this.ID, !this.isWaitList)
        // this.$emit('update:isWaitList', !this.isWaitList)
        this.showing  = false
      } catch (error) {
        console.log(error)
        this.showing = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      
    }
  },

  components: {
    acceptDialog: () => import('@/components/global/ConfirmationDialog.vue')
  }
}
</script>